<i18n>
ru:
  changeQuery: Попробуйте изменить запрос или вернуться в меню
  label: Для начала поиска введите 3 символа и более
  noDish: Такого блюда у нас нет...
  showTextInPriceButton: от
ua:
  changeQuery: Спробуйте змінити запит або повернутися до меню
  label: Для початку пошуку введіть 3 символи та більше
  noDish: Такої страви у нас немає...
  showTextInPriceButton: від
us:
  changeQuery: Try changing the query or return to the menu
  label: 'To start searching, enter 3 characters and more'
  noDish: We do not have such a product...
  showTextInPriceButton: from
</i18n>

<template>
  <common-typeahead-input
    focus-on-mount
    show-clear-icon
    show-search-icon
    :input="userInputSearch"
    :label="translate('search.label')"
    :min-chars="3"
    :on-close="onClose"
    :user-input-changed="userInputSearchChanged"
    :zero-results="translate('search.noDish')"
    :zero-results-subtitle="translate('search.changeQuery')"
    data-test-id="menu-search"
  >
    <template #titleEnd="item: ProductSearchResult">
      <div class="v-d-flex v-mr-xs">
        <common-currency
          v-if="item.Price > 0"
          class="v-menu-search-title-end"
          :amount="item.Price"
        />
        <template v-else-if="item.MinimalOptionPrice > 0">
          <span
            class="v-menu-search-title-end v-mr-xxs"
            v-html="translate('productCard.showTextInPriceButton')"
          />
          <common-currency
            class="v-menu-search-title-end"
            :amount="item.MinimalOptionPrice"
          />
        </template>
      </div>
    </template>
  </common-typeahead-input>
</template>

<script setup lang="ts">
import type { TypeaheadOptions } from '~types/common'
import type { Group, ProductSearchResult } from '~types/menuStore'

import type { GUID } from '@arora/common'

import { Guid } from '~api/consts'

defineProps<{
  onClose: () => void
}>()

const menuStore = useMenuStore()

const { translate } = useI18nSanitized()

const { convertLayout } = useKeyboardCheck()

const { applyProductsFilter } = useProduct()
const appConfig = useAppConfig()

const userInputSearch = ref<string>('')

function getSubtitle(groupId: GUID): string {
  let group: Group | undefined
  let groupIdRunner = groupId
  const result: string[] = []

  do {
    group = appConfig.Groups.find((g) => g.ID === groupIdRunner)
    if (group) {
      groupIdRunner = group.ParentGroupID
      result.push(group.Name)
    }
  } while (!Guid.IsNullOrEmpty(group?.ParentGroupID ?? Guid.Empty))

  result.reverse()
  return result.join(' · ')
}

async function userInputSearchChanged(value: string): Promise<TypeaheadOptions<ProductSearchResult>[]> {
  if (value.length < 3) return []

  const products = await menuStore.getProductsBySearchString(value, convertLayout(value))

  return products
    .filter((product) => {
      return applyProductsFilter(product)
    })
    .map((product) => {
      return {
        description: product.Description,
        disabled: menuStore.StopListProductIds.has(product.ID),
        ID: product.ID,
        image: product.SmallImage,
        item: product,
        onClick: () => {
          navigateTo(product.Link)
        },
        subtitle: getSubtitle(product.GroupID),
        title: menuStore.StopListProductIds.has(product.ID)
          ? `[${translate('productCard.inStopListButton')}] ${product.Name}`
          : product.Name
      }
    })
}
</script>

<style lang="scss">
@use 'assets/variables';

.v-menu-search-title-end {
  color: variables.$BodyTextColor;
}
</style>
